:root {
  --lukhu-violet: #4a4062;
  --lukhu-ruby: #d81e5b;
  --lukhu-black: #111111;
  --lukhu-white: #ffffff;
  --click-blue: #4900ff;
  --lukhu-light-blue: #d1eaff;
  --lukhu-gray: #717171;
  --lukhu-baby-blue: #a2d2fe;
  --lukhu-gray-2: #495057;
  --lukhu-gray-3: #8e8e8e;
  --lukhu-gray-4: #7b7b7b;
  --lukhu-green: #1e802c;
  --font-family-montserrat: 'Montserrat', sans-serif;
  --font-family-lato: 'Lato', sans-serif;
  --font-family-syncopate: 'Syncopate', sans-serif;
  --font-style-normal: normal;
  --font-weight-light: 300;
  --font-weight-medium: medium;
  --font-weight-semibold: 600;
  --font-weight-bold: bold;
  --font-weight-normal: normal;
  --font-size-10: 0.625rem;
  --font-size-12: 0.75rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-20: 1.25rem;
  --font-size-24: 1.5rem;
  --font-size-32: 2rem;

  --character-spacing--0-28: -0.28px;
  --character-spacing--0-33: -0.33px;
  --character-spacing--0-39: -0.39px;
  --character-spacing--0-44: -0.44px;
  --character-spacing--0-5: -0.5px;
  --character-spacing--0-58: -0.58px;
  --character-spacing--0-67: -0.67px;
  --character-spacing--0-72: -0.72px;
  --character-spacing--1: -1px;
  --character-spacing--1-33: -1.33px;
  --character-spacing--1-78: -1.78px;
  --line-spacing-12: 12px;
  --line-spacing-13: 13px;
  --line-spacing-15: 15px;
  --line-spacing-17: 17px;
  --line-spacing-18: 18px;
  --line-spacing-19: 19px;
  --line-spacing-22: 22px;
  --line-spacing-25: 25px;
  --line-spacing-29: 29px;
  --line-spacing-32: 32px;
  --line-spacing-44: 44px;
  --line-spacing-58: 58px;
  --line-spacing-78: 78px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.txt-capitalize {
  text-transform: capitalize;
}
.txt-uppercase {
  text-transform: uppercase;
}

.base-btn {
  background-color: red;
}
.ctn-main {
  max-width: 1440px;
}
.wrapper-main {
  display: flex;
  justify-content: center;
}

.white-card {
  background-color: var(--lukhu-white);
}

.txt-ruby {
  color: var(--lukhu-ruby);
}

.txt-violet {
  color: var(--lukhu-violet);
}
.txt-white {
  color: var(--lukhu-white);
}
.txt-lightColor {
  color: #707070;
}
.txt-gray32 {
  color: #525252;
}
.txt-hanPurple {
  color: #481cfb;
}
.txt-elecViolet {
  color: #601dff;
}
.txt-gray2 {
  color: var(--lukhu-gray-2);
}
.txt-black {
  color: var(--lukhu-black);
}
.txt-green {
  color: var(--lukhu-green);
}
.txt-gray3 {
  color: var(--lukhu-gray-3);
}

.txt-gray4 {
  color: var(--lukhu-gray-4);
}
.txt-bold {
  font-weight: var(--font-weight-bold);
}
.txt-regular {
  font-weight: var(--font-weight-normal);
}
.txt-medium {
  font-weight: var(--font-weight-medium);
}
.txt-light {
  font-weight: var(--font-weight-light);
}
.txt-semi-bold {
  font-weight: 600;
}

.txt-lato {
  font-family: var(--font-family-lato);
}

.txt-mont {
  font-family: var(--font-family-montserrat);
}

.h2x-large-bold {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-spacing-32);
  /* letter-spacing: var(--character-spacing--0-72); */
}

.h2x-large-medium {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-26);
  line-height: var(--line-spacing-32);
  letter-spacing: var(--character-spacing--0-72);
}

.h2x-large-light {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-26);
  line-height: var(--line-spacing-32);
  letter-spacing: var(--character-spacing--0-72);
}

.h3x-large-bold {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-36);
  line-height: var(--line-spacing-44);
  letter-spacing: var(--character-spacing--1);
}

.h3x-large-medium {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-36);
  line-height: var(--line-spacing-44);
  letter-spacing: var(--character-spacing--1);
}

.h3x-large-light {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-36);
  line-height: var(--line-spacing-44);
  letter-spacing: var(--character-spacing--1);
}

.h4x-large-bold {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-48);
  line-height: var(--line-spacing-58);
  letter-spacing: var(--character-spacing--1-33);
}

.h4x-large-medium {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-48);
  line-height: var(--line-spacing-58);
  letter-spacing: var(--character-spacing--1-33);
}

.h5x-large-light {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-48);
  line-height: var(--line-spacing-58);
  letter-spacing: var(--character-spacing--1-33);
}

.h5x-large-bold {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-64);
  line-height: var(--line-spacing-78);
  letter-spacing: var(--character-spacing--1-78);
}

.h5x-large-medium {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-64);
  line-height: var(--line-spacing-78);
  letter-spacing: var(--character-spacing--1-78);
}

.h5x-large-light {
  font-family: var(--family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-64);
  line-height: var(--line-spacing-78);
  letter-spacing: var(--character-spacing--1-78);
}
.section-title {
  font-family: var(--font-family-montserrat);
  font-weight: var(--font-weight-semibold);
}

.h3-16 {
  font-size: 16px;
}
.btn-blue-filled {
  background-color: var(--click-blue);
}
.f-12 {
  font-size: var(--font-size-12);
}
.f-14 {
  font-size: var(--font-size-14);
}
.f-16 {
  font-size: var(--font-size-16);
}
.f-18 {
  font-size: var(--font-size-18);
}
.f-20 {
  font-size: var(--font-size-20);
}
.f-24 {
  font-size: var(--font-size-24);
}

.ff-Montserrat {
  font-family: var(--font-family-montserrat);
}
.fullWidth {
  width: 100%;
}

.d-flex {
  display: flex;
}
.d-flex-wrap {
  flex-wrap: wrap;
}
.d-flex-space {
  justify-content: space-between;
}
.d-flex-align-center {
  align-items: center;
}
.d-flex-center {
  justify-content: center;
  align-items: center;
}
.d-flex-col {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.mb-4 {
  margin-bottom: 4px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-24 {
  margin-bottom: 24px;
}

.mt-4 {
  margin-top: 4px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-24 {
  margin-top: 24px;
}

.p-16 {
  padding: 16px;
}
.p-8 {
  padding: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.pt-16 {
  padding-top: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pt-24 {
  padding-top: 24px;
}
.w-full {
  width: 100%;
}
input {
  padding: 12px 8px;
  background-color: '#F5F5F5';
  background: #f5f5f5;
  border: none;
  border-radius: 4px;
}

::placeholder {
  font-size: inherit;
  font-family: var(--font-family-montserrat);
  /* text-transform: capitalize; */
}
input[type='text']:focus {
  outline: none;
  border: none;
}

.bg-section {
  background: #f5f6f8;
}
.bg-white {
  background-color: #ffffff;
}
.txt-center {
  text-align: center;
}

.cat4 {
  position: relative;
  background-color: var(--click-blue);
  border-radius: 2px;
}
.cat-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.cat-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 16px;
}
.cat-grid > div {
  height: 120px;
  width: 280px;
}
.input-error {
  color: red;
  font-size: 14px;
}
.input-success {
  color: green;
  font-size: 12px;
}
.app-logo {
  width: 50%;
}
.brand-logo {
  width: 15%;
}
.top-nav {
  display: flex;
  margin: 16px 16px 16px 64px;
  justify-content: space-between;
  align-items: center;
}
.blur {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  height: 100vh;
  width: 100vw;
}
/* .bg-main {
  position: relative;
  height: 100%; 
  background-color: #eee;
} */

/* .bg-main::before {
  content: '';
  background-image: url('../images/clothe_rack_2.jpg');
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.1; 
} */

.beta-content {
  position: relative;
  margin-top: 48px;
}
.bg-content {
  position: relative;
  margin-top: 12vh;
}
.bg-home {
  background-color: #f5f5f5;
}

.header-home {
  width: 100%;
  background-color: var(--lukhu-white);
}
.nav-home {
  padding: 24px 0;
}
.nav-home > ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.nav-home > ul > li > a {
  color: inherit;
  text-transform: capitalize;
  margin-right: 1rem;
}
.nav-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #717171;
  height: auto;
  width: fit-content;
}

.list-product {
  width: 100%;
  margin: 16px;
}
.sctn-centered-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0 0 16px;
}
.sctn-centered-header > a {
  color: var(--click-blue);
}
.sctn-centered-header > a:visited {
  color: var(--click-blue);
}

.list-product-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 48px 0;
}
.image-upload-wrap-disabled {
  max-width: 160px;
  height: 160px;
  background: #f5f2fe;
}

.image-upload-wrap {
  max-width: 160px;
  height: 160px;
  border: 1px dashed #707070;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 auto; */
}

.image-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.image-upload-text {
  text-align: center;
}
.image-upload-text p {
  color: #6b6b6b;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
}

.item-image-grid {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-row-gap: 12px;
  margin: 0 auto;
}

/* PROMO BANNER */
.prom-banner-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  /* height: 50vh; */
}

.prom-banner-title {
  padding-top: 24px;
  padding-bottom: 24px;
  text-align: center;
  width: 100%;
  display: flex;
  place-items: center;
}

.prom-banner-media {
  width: 100%;
}
.prom-banner-media img {
  height: 320px;
}

/* VIEW SINGLE PRODUCT  */
.single-prod-grid {
  width: 100%;
  margin: 2%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 2fr 2fr;
  height: '90vh';
}
.single-prod-flex {
  display: flex;
}

.single-prod-ctn {
  width: 85%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.footer {
  background-color: var(--lukhu-violet);
  color: var(--lukhu-white);
  padding: 32px 0;
}
.footer p {
  font-size: 12px;
  text-transform: capitalize;
}

.mx-pg {
  padding-left: 0;
  padding-right: 0;
}

.d-opt-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.d-grid-categories {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 4px;
}

.disp-empty {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 600px) {
  .item-image-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 12px;
    grid-template-rows: 1fr;
  }
  .bg-beta {
    height: 100vh;
  }
}

.nav-home-secondary {
  display: grid;
  justify-content: start;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}
.prom-banner-media img {
  height: 512px;
}
.bg-main {
  height: 100%; /* was 100vh*/
}

@media screen and (min-width: 1024px) {
  .bg-main {
    height: 100vh; /* was 100vh*/
  }
  .item-image-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 12px;
  }
  .nav-home > ul {
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 64px;
  }

  .nav-home-secondary {
    justify-content: center;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
  }

  /* repeated? */
  .nav-home > ul > li {
    margin-right: 1rem;
  }
  .listing-grid {
    padding: 16px 64px;
  }
  /* .list-product {
    width: 60%;
    margin: 0 auto;
  } */
  .sctn-centered {
    width: 50%;
    margin: 0 auto;
  }

  /* PROMO BANNER */
  .prom-banner-wrapper {
    flex-direction: row;
    height: 50vh;
  }
  .prom-banner-title {
    width: 60%;
    margin-bottom: 0;
    text-align: left;
  }

  .prom-banner-media {
    width: 40%;
  }

  .prom-banner-media img {
    height: 100%;
  }

  .d-opt-header {
    flex-direction: row;
  }
  .list-tile-flex {
    display: flex;
    flex-flow: row wrap;
  }

  /* VIEW SINGLE PRODUCT */
  .single-prod-grid {
    width: 90%;
    height: '60vh';
    margin: 0 auto;
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    grid-template-rows: auto;
  }
  .single-prod-flex {
    flex-direction: column;
    justify-content: space-between;
  }

  .single-prod-ctn {
    flex-direction: row;
    justify-content: space-between;
  }

  .img-list-wrap {
    width: 20%;
  }
  .img-disp-wrap {
    width: 40%;
  }
  .info-wrap {
    width: 40%;
  }

  .side-nav {
    width: 20%;
    padding: 0 24px;
  }
  /* .d-cat-wrapper {
    display: grid;
    grid-template-columns: 20% 80%;
  } */
  .mx-pg {
    padding-left: 48px;
    padding-right: 48px;
  }
  .d-grid-categories {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 4px;
  }
  .sctn-centered-header {
    padding: 32px 0 0 0;
  }
  .bg-beta {
    height: 100%;
    min-height: 100vh;
  }
}
